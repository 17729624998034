import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Design flexible, robust, and repeatable patterns that build on a set of solid foundations.`}</p>
    <h2>{`Design system foundations`}</h2>
    <p>{`Primer is the stable trusted layer. At its foundations are typography, color, spacing, and layout.`}</p>
    <p>{`➡️ `}<a parentName="p" {...{
        "href": "https://primer.style/"
      }}>{`Primer design guidelines`}</a></p>
    <h2>{`Upcoming patterns and proposals`}</h2>
    <p>{`To avoid duplicated efforts and to accommodate upcoming changes, keep up with the Primer Teams Backlog.`}</p>
    <p>{`➡️ `}<a parentName="p" {...{
        "href": "https://github.com/orgs/github/projects/4503"
      }}>{`Primer Teams Backlog`}</a>{` (GitHub staff only)`}</p>
    <h2>{`Context and use cases`}</h2>
    <p>{`When creating a new pattern, we advise starting with an audit of similar patterns that already exist as well as identifying opportunity areas where the new pattern can be used. Consider as many different use cases as possible. The goal is to create patterns that are flexible, reusable, and that solve concrete problems.`}</p>
    <p>{`Finally, consider where a pattern will live in the context of a system. A pattern designed for a specific context is not likely to be considered a reusable component.`}</p>
    <h2>{`Share early, and often`}</h2>
    <p>{`There are many opportunities to get feedback from Primer maintainers.`}</p>
    <p><strong parentName="p">{`Systems designers`}</strong>{` provide extra context and considerations for designing with system-wide considerations
`}<strong parentName="p">{`Accessibility designers`}</strong>{` provide context and considerations for accessible and inclusive design
`}<strong parentName="p">{`Product designers`}</strong>{` consider the designs in the context of their own product area and whether their specific use cases could benefit from your work
`}<strong parentName="p">{`Design engineers`}</strong>{` can consider how something may be implemented, what is realistic within the existing constraints, and what is unfeasible.`}</p>
    <p>{`There are different ways you can ask for and get feedback:`}</p>
    <ul>
      <li parentName="ul">{`(GitHub staff only) `}<a parentName="li" {...{
          "href": "https://github.com/github/primer/#need-a-primer-pattern-or-octicon-"
        }}>{`Open an issue requesting a Primer Design Review`}</a></li>
      <li parentName="ul">{`(GitHub staff only) Attend Primer or Primer Accessibility open office hours`}</li>
    </ul>
    <h2>{`Design checklist`}</h2>
    <p>{`All design patterns for Primer Library consideration should at minimum:`}</p>
    <ul {...{
      "className": "contains-task-list"
    }}>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<strong parentName="li">{`Leverage Primer`}</strong>{`: Leverages the foundational building blocks and tools of Primer. Reuses or extends existing patterns and primitives rather than creating overrides`}
        <ul parentName="li" {...{
          "className": "contains-task-list"
        }}>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Colors, spacing, sizes, and typographic styles from `}<a parentName="li" {...{
              "href": "https://github.com/primer/primitives/"
            }}>{`@primer/primitives`}</a></li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`All icons come from `}<a parentName="li" {...{
              "href": "https://github.com/primer/octicons/"
            }}>{`@primer/octicons`}</a></li>
          <li parentName="ul" {...{
            "className": "task-list-item"
          }}><input parentName="li" {...{
              "type": "checkbox",
              "checked": false,
              "disabled": true
            }}></input>{` `}{`Considers the criteria in the `}<a parentName="li" {...{
              "href": "https://primer.style/foundations/responsive"
            }}>{`responsive design guidelines`}</a></li>
        </ul>
      </li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<strong parentName="li">{`Maintain high design quality:`}</strong>{` Visually in harmony with GitHub UI and all visual elements are used are in their correct context`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<strong parentName="li">{`Prioritize code as a top concern:`}</strong>{` Designed with code quality and component API top of mind`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<strong parentName="li">{`Be inclusive:`}</strong>{` Designs follow Primer `}<a parentName="li" {...{
          "href": "https://primer.style/guides/accessibility/guidelines"
        }}>{`accessibility guidelines`}</a>{` and considers a global audience`}</li>
      <li parentName="ul" {...{
        "className": "task-list-item"
      }}><input parentName="li" {...{
          "type": "checkbox",
          "checked": false,
          "disabled": true
        }}></input>{` `}<strong parentName="li">{`Have documentation:`}</strong>{` Design patterns are `}<a parentName="li" {...{
          "href": "https://primer.style/guides/contribute/documentation"
        }}>{`documented`}</a>{` for easy adoption`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      