import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import Code from '@primer/gatsby-theme-doctocat/src/components/code';
import doLinks31 from '../../../src/images/accessibility/links/do-links-3_1.png';
import dontLinks31 from '../../../src/images/accessibility/links/dont-links-3_1.png';
import doLinksIcon from '../../../src/images/accessibility/links/do-links-icon-or-shape.png';
import dontLinksIcon from '../../../src/images/accessibility/links/dont-links-icon-or-shape.png';
import doLinksUnderlined from '../../../src/images/accessibility/links/do-links-underlined.png';
import dontLinksUnderlined from '../../../src/images/accessibility/links/dont-links-underlined.png';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Overview`}</h2>
    <p>{`Links are user interface elements that `}<em parentName="p">{`navigate`}</em>{` you to a new place or new content. Contrast this with buttons, which are designed to `}<em parentName="p">{`activate`}</em>{` a feature.`}</p>
    <h2>{`Why?`}</h2>
    <p>{`Links can do things like help with page context, reference similar items of interest, and allow for endless connected information surfing through sites like Wikipedia. Links play a key part in your experience on the web, but without proper consideration they can be frustrating to use, skipped over, or completely unnoticed.`}</p>
    <p>{`For screen reader assistive technology, links and buttons are expected to function differently from each other. If a link is activated and does not do what was expected, that can be disorienting and frustrating.`}</p>
    <p>{`A common way a screen reader might navigate the page is by going through a list of all the links on the page. Without context, "read more" or "click here" links are not helpful.`}</p>
    <p>{`People who have low or colorblind vision may have trouble identifying links that just use color to distinguish them from plain text, this is why keeping the underline styling on links within body text is important for identification.`}</p>
    <h2>{`How to test links`}</h2>
    <h3>{`Functionality and purpose`}</h3>
    <p>{`A link's function is to `}<em parentName="p">{`navigate`}</em>{` to a different page or new content. If instead a feature on the page is activated, use a `}<inlineCode parentName="p">{`<button>`}</inlineCode>{`. `}<a parentName="p" {...{
        "href": "https://accessibility-playbook.github.com/link-and-button-guidance"
      }}>{`Learn when to use a link or button`}</a>{`.`}</p>
    <p>{`A link's purpose must be obvious from the link text alone. If you can't get an idea of where a link will take you based on the link text without reading the sourrounding text, the link text should be updated. `}<a parentName="p" {...{
        "href": "#writing-link-text"
      }}>{`Learn how to write good link text`}</a>{`.`}</p>
    <p>{`This is important because screen readers allow users to browse through a list of links, where the link text is the only clue of where a link will take you.`}</p>
    <h3>{`Visual distinction & contrast`}</h3>
    <p>{`Like normal text, a link must have a `}<inlineCode parentName="p">{`4.5:1`}</inlineCode>{` contrast against the background color that it is placed on. Use a `}<a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`contrast checker`}</a>{` to validate that your link meets this required contrast.`}</p>
    <p>{`If a link is sourrounded by text, it must be underlined or pass a `}<inlineCode parentName="p">{`3:1`}</inlineCode>{` contrast against the sourrounding text as well. Alternativly an icon, a background shape or an outline can demarcate a link.`}</p>
    <p>{`Some examples of this are:`}</p>
    <ul>
      <li parentName="ul">{`links within body text`}</li>
      <li parentName="ul">{`a headline and subline which both are individual links`}</li>
      <li parentName="ul">{`issue numbers or usernames within a commit line`}</li>
    </ul>
    <h2>{`Guidelines`}</h2>
    <h3>{`For designers`}</h3>
    <ul>
      <li parentName="ul">{`Visually demarcate your links by using:`}
        <ul parentName="li">
          <li parentName="ul">{`the `}<inlineCode parentName="li">{`accent.fg`}</inlineCode>{` color in combination with `}<inlineCode parentName="li">{`fg.default`}</inlineCode>{` for the sourrounding text on any of the `}<inlineCode parentName="li">{`canvas.[...]`}</inlineCode>{` colors`}</li>
          <li parentName="ul">{`an underline for the link text`}</li>
          <li parentName="ul">{`an icon before or after the link text`}</li>
          <li parentName="ul">{`using a background shape behind the link`}</li>
          <li parentName="ul">{`using a link color that has a `}<inlineCode parentName="li">{`3:1`}</inlineCode>{` contrast against the sourrounding text color AND a `}<inlineCode parentName="li">{`4.5:1`}</inlineCode>{` against the background color`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Make sure a link's purpose can be understood from the link text alone, without needing the surrounding context.`}</li>
      <li parentName="ul">{`Links should look like links, not buttons, except in rare circumstances, like calls to action.`}</li>
    </ul>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/40274682/160483376-a88eb64f-ce80-4a11-93b5-6d882b1970eb.png" alt="Markdown paragraph with descriptive links" />
    <Caption mdxType="Caption">Be descriptive with your links so that they can stand alone and be understood.</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/40274682/160474793-2fd3085f-0813-43ed-8b43-c1a5f14aaea9.png" alt="Markdown paragraph with links like 'Click here' and 'find out more'" />
    <Caption mdxType="Caption">Don't use generic terms like 'click here' that can't be understood out of context.</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img src="https://user-images.githubusercontent.com/40274682/160474794-06a75b30-06d3-475e-863f-605e8a6dad72.png" alt="Underlined username link in issue subheading" />
    <Box sx={{
          marginTop: 2,
          backgroundColor: 'neutral.subtle',
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Underlined links in paragraph" src={doLinksUnderlined} />
    </Box>
    <Caption mdxType="Caption">Underline links in paragraphs and sentences</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img src="https://user-images.githubusercontent.com/40274682/160474800-b505369e-1730-4c14-b426-70b5bd016a6c.png" alt="Issue subheader with none of the links underlined" />
    <Box sx={{
          backgroundColor: 'neutral.subtle',
          marginTop: 2,
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Links using underline to highlight important text instead of link" src={dontLinksUnderlined} />
    </Box>
    <Caption mdxType="Caption">Don't use underlines for non-link highlights or forget to demarcate links</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Box sx={{
          backgroundColor: 'neutral.subtle',
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Links using the accent color and a muted grey both with a 3:1 contrast against the default text color" src={doLinks31} />
    </Box>
    <Caption mdxType="Caption">Use a link color that has a 3:1 contrast against the text color</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Box sx={{
          backgroundColor: 'neutral.subtle',
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Links using a color with a contrast smaller than 3:1 against the default text color" src={dontLinks31} />
    </Box>
    <Caption mdxType="Caption">Don't use a color that has less than a 3:1 contrast against the text color for links</Caption>
  </Dont>
    </DoDontContainer>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Box sx={{
          backgroundColor: 'neutral.subtle',
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Links with icon or background shape to demarcate it" src={doLinksIcon} />
    </Box>
    <Caption mdxType="Caption">Use an icon or a background shape to demarcate a link</Caption>
  </Do>
  <Dont mdxType="Dont">
    <Box sx={{
          backgroundColor: 'neutral.subtle',
          borderRadius: 6
        }} mdxType="Box">
      <img width="550" alt="Links using other text styles to demaracte it" src={dontLinksIcon} />
    </Box>
    <Caption mdxType="Caption">Don't use text styles like italic or bold to demarcate links</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`For engineers`}</h3>
    <ul>
      <li parentName="ul">{`Use Primer link components:`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/../components/link/rails"
            }}>{`Rails component`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/../components/link/react"
            }}>{`React component`}</a></li>
        </ul>
      </li>
      <li parentName="ul">{`Don't override the link styling provided in the components`}</li>
      <li parentName="ul">{`Make sure links receive our global focus indicator styling when navigating the page with a keyboard (reference: `}<a parentName="li" {...{
          "href": "/guides/accessibility/focus-management"
        }}>{`Focus management`}</a>{`)`}</li>
      <li parentName="ul">{`Don't use the `}<inlineCode parentName="li">{`title`}</inlineCode>{` attribute`}
        <ul parentName="li">
          <li parentName="ul">{`Content within a `}<inlineCode parentName="li">{`title`}</inlineCode>{` is inaccessible for many users, such as touch-only and keyboard-only users. If additional content needs to be associated with a link consider using a `}<a parentName="li" {...{
              "href": "https://primer.style/view-components/components/alpha/tooltip"
            }}>{`tooltip`}</a>{` or `}<a parentName="li" {...{
              "href": "/guides/accessibility/tooltip-alternatives"
            }}>{`alternatives to a tooltip`}</a>{`.`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Avoid adding side effects to link click events. Links should navigate, not affect the page.`}</li>
      <li parentName="ul">{`Don't force links to open in a new tab/window by setting the `}<inlineCode parentName="li">{`target`}</inlineCode>{` property.`}</li>
      <li parentName="ul">{`Links should always open in a new window when clicking while holding Command/Control.`}</li>
    </ul>
    <h2>{`Common mistakes`}</h2>
    <ul>
      <li parentName="ul">{`Only adding an underline to a link when it has focus or is hovered over`}</li>
      <li parentName="ul">{`Underlining every link on the page. For example, a navigation list is a list of links but navigational links don't have to be underlined because the intent is understood and an underline is not needed to identify the interactive nature of the control.`}</li>
    </ul>
    <h2>{`Writing link text`}</h2>
    <p>{`Link text should be descriptive enough to convey the destination without relying on the surrounding text. Screen reader users often tab through links on a page to quickly find content without needing to listen to the full page.
When link text is too generic like "Read more", the destination of the link is not conveyed.`}</p>
    <p>{`It may be acceptable in some scenarios to provide a more descriptive link text for screen reader users by setting the `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{`. However, this technique will result in divergence between the label and the text and is not an ideal, future-proof solution.
Whenever possible, prefer a single descriptive link text that is available for both sighted users and screen reader users.`}</p>
    <p>{`If you must resort to the ARIA technique to provide a descriptive link text, follow these principles:`}</p>
    <ol>
      <li parentName="ol">{`The visible text is included in full as part of the accessible name to avoid a violation of `}<a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/label-in-name.html"
        }}>{`SC 2.5.3: Label in Name`}</a>{`.`}</li>
      <li parentName="ol">{`The sentence that the link is a part of is well-formed and grammatical when read with both the visible text and the accessible name.`}</li>
    </ol>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <Caption mdxType="Caption">
      Accessible name fully includes the visible link text, "Learn more" and is well-formed with either label.
    </Caption>
    <Code className="language-html" mdxType="Code">{`There are various plans available. 
<a href="..." aria-label="Learn more about GitHub pricing plans">Learn more</a>`}</Code>
    <br />
  </Do>
  <Dont mdxType="Dont">
    <Caption mdxType="Caption">
      Accessible name results in poorly-formed sentence, "Learn more about keyboard shortcuts allow you to access common
      commands more quickly".{' '}
    </Caption>
    <Code className="language-html" mdxType="Code">{`<a href="..." aria-label="Learn more about keyboard shortcuts">Keyboard shortcuts</a> allow you to access common commands more quickly.`}</Code>
  </Dont>
    </DoDontContainer>
    <p>{`As demonstrated in the examples, this technique adds more complexity to the code and can introduce more problems than it solves so only use this technique if absolutely necessary.`}</p>
    <h2>{`Additional resources`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://dequeuniversity.com/class/visual-design/color/distinguish-links-from-text"
        }}>{`Color to Distinguish Links from Text`}</a>{` (requires a subscription to access the content)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://accessibilityinsights.io/en/"
        }}>{`Accessibility Insights plugin`}</a>{`: With the Accessibility Insights plugin installed, open the plugin, visit the `}<strong parentName="li">{`Assessment`}</strong>{` option and select `}<strong parentName="li">{`7. Links`}</strong></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://webaim.org/techniques/hypertext/"
        }}>{`WebAIM: Links and Hypertext - Introduction to Links and Hypertext`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/github/erblint-github/blob/main/docs/rules/accessibility/avoid-generic-link-text-counter.md"
        }}>{`erblint-github: Avoid generic link text`}</a></li>
    </ul>
    <h3>{`Related WCAG guidelines and success criteria`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/name-role-value.html"
        }}>{`Understanding Success Criterion 4.1.2: Name, Role, Value`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#link"
        }}>{`Authoring Practices Guide - Links`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-1.2/#link"
        }}>{`Accessible Rich Internet Applications (WAI-ARIA) 1.2 (w3.org)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.w3.org/WAI/WCAG21/Understanding/link-purpose-in-context.html"
        }}>{`Understanding Success Criterion 2.4.4: Link Purpose (In Context)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      