import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Dotcom Codespaces (staff-only)`}</h2>
    <p>{`Are you a GitHub employee (Hubber) looking to make a change to primer/react or another Primer library? Take a look at our documentation for the `}<a parentName="p" {...{
        "href": "https://github.com/github/primer-engineering/blob/main/how-we-work/developing-in-dotcom-codespaces.md"
      }}><inlineCode parentName="a">{`primerize`}</inlineCode>{` tool`}</a>{` (GitHub staff only), which can help get you started in a dotcom codespace.`}</p>
    <h2>{`Getting started`}</h2>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Install `}<inlineCode parentName="p">{`@primer/react`}</inlineCode>{` and its peer dependencies:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`npm install @primer/react react react-dom styled-components
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Wrap the root of your application with `}<inlineCode parentName="p">{`ThemeProvider`}</inlineCode>{` and `}<inlineCode parentName="p">{`BaseStyles`}</inlineCode>{`:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {ThemeProvider, BaseStyles} from '@primer/react'

function App() {
  return (
    <ThemeProvider>
      <BaseStyles>
        <div>...</div>
      </BaseStyles>
    </ThemeProvider>
  )
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Import components from `}<inlineCode parentName="p">{`@primer/react`}</inlineCode>{` and use them in your application:`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-jsx"
          }}>{`import {Button} from '@primer/react'

function MyComponent() {
  return <Button>Click me</Button>
}
`}</code></pre>
      </li>
    </ol>
    <h3>{`Polyfills & Browser Support`}</h3>
    <p>{`Primer React supports the current versions of `}<a parentName="p" {...{
        "href": "https://www.google.com/chrome/"
      }}>{`Chrome`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.mozilla.org/firefox/"
      }}>{`Firefox`}</a>{`, `}<a parentName="p" {...{
        "href": "http://www.apple.com/safari/"
      }}>{`Safari`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://www.microsoft.com/en-us/windows/microsoft-edge"
      }}>{`Microsoft Edge`}</a>{`, as well as the `}<a parentName="p" {...{
        "href": "https://www.mozilla.org/en-US/firefox/organizations/"
      }}>{`Firefox Extended Support Release`}</a>{`. This is consistent with `}<a parentName="p" {...{
        "href": "https://docs.github.com/en/free-pro-team@latest/github/getting-started-with-github/supported-browsers"
      }}>{`GitHub's Browser Support`}</a>{`.`}</p>
    <p>{`Primer React does not transform code to support older ECMAScript versions (eg. ES5), and uses ECMAScript features like `}<inlineCode parentName="p">{`Object.assign`}</inlineCode>{` and syntax features like native classes and Object destructuring and spreading.`}</p>
    <p>{`Any environment that uses Primer React should have all the necessary polyfills installed to comply with the latest code standards, as Primer React does not ship with them. Additionally, as Primer React does not transform code to support older versions, it may be necessary for projects to transform the code if support for older browsers (such as Edge 18) is needed.`}</p>
    <h3>{`Minimizing bundle size`}</h3>
    <p>{`Module bundlers that use ECMAScript modules (ESM) will automatically tree-shake Primer React, ensuring that no unused code is included in your final bundle. However, if you're not using ESM, you may be able to drastically reduce the size of your final bundle by importing components individually from the `}<inlineCode parentName="p">{`lib`}</inlineCode>{` subfolder:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// using import syntax
import Box from '@primer/react/lib/Box'
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// using require syntax
const Box = require('@primer/react/lib/Box')
`}</code></pre>
    <p>{`Note that the modules in the `}<inlineCode parentName="p">{`lib`}</inlineCode>{` folder are CommonJS-style modules; if you're using ESM and a compatible module bundler, importing files individually from `}<inlineCode parentName="p">{`lib`}</inlineCode>{` provides no benefit.`}</p>
    <h3>{`Peer dependencies`}</h3>
    <p>{`Primer React ships with a few libraries labeled as peer dependencies. These libraries are commonly already installed in host projects and installing multiple versions can introduce errors.`}</p>
    <p>{`Primer React requires the following peer dependencies:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`styled-components`}</inlineCode>{` at version 4.0.0 or higher`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react`}</inlineCode>{` at versions 17.x or higher`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`react-dom`}</inlineCode>{` at versions 17.x or higher`}</li>
    </ul>
    <h2>{`BaseStyles`}</h2>
    <p>{`In order to set baseline color, font-family, and line-heights across your project, you will need to establish base Primer styles for your app by wrapping all of your Primer components in `}<inlineCode parentName="p">{`<BaseStyles>`}</inlineCode>{` at the root of your app:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import {BaseStyles, Box, Heading} from '@primer/react'

export default () => (
  <BaseStyles>
    <Box m={4}>
      <Heading as="h2" sx={{mb: 2}}>
        Hello, world!
      </Heading>
      <p>This will get Primer text styles.</p>
    </Box>
  </BaseStyles>
)
`}</code></pre>
    <p>{`This will apply the same `}<inlineCode parentName="p">{`color`}</inlineCode>{`, `}<inlineCode parentName="p">{`font-family`}</inlineCode>{`, and `}<inlineCode parentName="p">{`line-height`}</inlineCode>{` styles to the `}<inlineCode parentName="p">{`<body>`}</inlineCode>{` as `}<a parentName="p" {...{
        "href": "https://github.com/primer/css/blob/main/src/base/base.scss#L15-L20"
      }}>{`Primer CSS's base styles`}</a>{`.`}</p>
    <h2>{`Static CSS rendering`}</h2>
    <p>{`If you're rendering React components both server- and client-side, we suggest following `}<a parentName="p" {...{
        "href": "https://www.styled-components.com/docs/advanced#server-side-rendering"
      }}>{`styled-component's server-side rendering instructions`}</a>{` to avoid the flash of unstyled content for server-rendered components.`}</p>
    <h2>{`TypeScript`}</h2>
    <p>{`Primer React includes TypeScript support and ships with its own typings. You will still need to install type definitions for peer dependencies if you import them in your own application code.`}</p>
    <p>{`Once installed, you can import components and their prop type interfaces from the `}<inlineCode parentName="p">{`@primer/react`}</inlineCode>{` package:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import {Button, ButtonProps} from '@primer/react'
`}</code></pre>
    <h3>{`Fixing "Duplicate identifier 'FormData'"`}</h3>
    <p>{`In versions `}<inlineCode parentName="p">{`4.1.19`}</inlineCode>{` and later, `}<inlineCode parentName="p">{`@types/styled-components`}</inlineCode>{` declares a dependency on both `}<inlineCode parentName="p">{`@types/react`}</inlineCode>{` and `}<inlineCode parentName="p">{`@types/react-native`}</inlineCode>{`. Unfortunately, those declarations clash; for more information, see `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33311"
      }}>{`issue 33311`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/DefinitelyTyped/DefinitelyTyped/issues/33015"
      }}>{`issue 33015`}</a>{` in the DefinitelyTyped repo.`}</p>
    <p>{`You may run into this conflict even if you're not importing anything from `}<inlineCode parentName="p">{`react-native`}</inlineCode>{` or don't have it installed. This is because some package managers hoist packages to the top-level `}<inlineCode parentName="p">{`node_modules`}</inlineCode>{` folder, and the TypeScript compiler automatically includes types from all folders in `}<inlineCode parentName="p">{`node_modules/@types`}</inlineCode>{` by default.`}</p>
    <p>{`The TypeScript compiler allows you to opt-out of this behavior `}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/tsconfig-json.html#types-typeroots-and-types"
      }}>{`using the `}<inlineCode parentName="a">{`typeRoots`}</inlineCode>{` and `}<inlineCode parentName="a">{`types`}</inlineCode>{` configuration options`}</a>{`. The best solution for this error, at least for now, seems to be to opt out of the automatic inclusion of `}<inlineCode parentName="p">{`node_modules/@types`}</inlineCode>{` and instead list the types you want to be included individually.`}</p>
    <p>{`In your `}<inlineCode parentName="p">{`tsconfig.json`}</inlineCode>{`, add to the `}<inlineCode parentName="p">{`types`}</inlineCode>{` array under the `}<inlineCode parentName="p">{`compilerOptions`}</inlineCode>{` like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "compilerOptions": {
    "types": ["node", "react", "styled-components", "jest"]
  }
}
`}</code></pre>
    <p>{`You'll need to customize the array based on the `}<inlineCode parentName="p">{`@types/`}</inlineCode>{` packages you have installed for your project.`}</p>
    <h2>{`Silencing warnings`}</h2>
    <p>{`Like React, Primer React emits warnings to the JavaScript console under certain conditions, eg. when using deprecated components or props. Similar to React, you can silence these warnings by setting the `}<inlineCode parentName="p">{`NODE_ENV`}</inlineCode>{` environment variable to `}<inlineCode parentName="p">{`production`}</inlineCode>{` during bundling.`}</p>
    <h2>{`Testing`}</h2>
    <p>{`Testing your application with Primer React is no different than testing your application with any other React library. Depending on your test environment and the testing libraries you use, you may need polyfills. For example, `}<inlineCode parentName="p">{`jest`}</inlineCode>{` runs via Node runtime and uses `}<a parentName="p" {...{
        "href": "https://github.com/jsdom/jsdom"
      }}>{`JSDOM`}</a>{` as a DOM implementation, meaning you will need to mock some browser APIs. We have `}<a parentName="p" {...{
        "href": "https://github.com/primer/react/blob/main/src/utils/test-helpers.tsx"
      }}>{`helpers`}</a>{` that can be used to mock some of these APIs. You can import the helpers in your test setup file like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import '@primer/react/lib-esm/utils/test-helpers' // For ESM
import '@primer/react/lib/utils/test-helpers' // For CommonJS
`}</code></pre>
    <h2>{`More information`}</h2>
    <p>{`See the `}<a parentName="p" {...{
        "href": "https://github.com/primer/react/tree/main/docs/content"
      }}>{`primer/react repository`}</a>{` for more information about how to use and contribute to Primer React. For component-specific documentation, check out the React section in the component's docs (example: `}<a parentName="p" {...{
        "href": "/components/action-list/react"
      }}>{`ActionList`}</a>{`).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      